.navbar {
  height: 50px;
  border-bottom: 0.5px solid rgb(231, 228, 228);
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #555;
  position: sticky;
  background-color: white;
  top: 0;
  z-index: 9;

  .wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    // background-color: purple;
    padding: 20px;

    .search {
      display: flex;
      align-items: center;
      border: 0.5 solid lightgray;
      padding: 3px;
      background-color: black;

      input {
        border: none;
        outline: none;
        background: transparent;

        &::placeholder {
          font-size: 12px;
        }
      }
    }
    .items {
      display: flex;
      align-items: center;

      .item {
        display: flex;
        align-items: center;
        margin: 20px;
        position: relative;
        // background-color: yellow;

        .icon {
          font-size: 18px;
        }
        .dropdown {
          position: relative;
          display: inline-block;

          .topAvatar {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            cursor: pointer;
          }

          .dropdown-content {
            display: none;
            position: absolute;
            background-color: #f9f9f9;
            min-width: 160px;
            box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
            z-index: 1;
          }

          &:hover .dropdown-content {
            display: block;
            right: 0;
          }
          .desc {
            padding: 15px;
            color: black;
            /* text-align: center; */
          }
        }
        .counter {
          width: 15px;
          height: 15px;
          background-color: red;
          border-radius: 50%;
          color: white;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 10px;
          font-weight: bold;
          position: absolute;
          top: -5px;
          right: -5px;
        }
      }
    }
  }
}
